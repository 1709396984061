import './styles.css'

const ContactUs = () => {
    return (
        <div className='container-copyright'>
            <p>Feito pelo PET Informática e o Diretório Acadêmico do Centro de Informática da UFPE</p>
        </div>
    );
}

export default ContactUs;